// import Swiper JS
import Swiper from 'swiper';
import {Navigation} from 'swiper/modules';

// import Swiper styles
import 'swiper/css';

const block = document.querySelector('.show-projects-block');

window.addEventListener('load', () => {
    initSlider();
    changeActive();
});

window.addEventListener('resize', () => {
    initSlider();
});

window.addEventListener('orientationchange', ()=> {
    initSlider();
})

function initSlider() {
    const mobileWidth = window.innerWidth <= 768;

    if (block) {
        new Swiper('.tabs-wrapper .swiper', {
            slidesPerView: 'auto',
            spaceBetween: mobileWidth ? 10 : 30,
        });

        if (mobileWidth) {
            new Swiper('.projects-wrapper .swiper', {
                modules: [Navigation],
                slidesPerView: 1,
                spaceBetween: 1,

                navigation: {
                    nextEl: ('.projects-wrapper .swiper-button-next'),
                    prevEl: ('.projects-wrapper .swiper-button-prev'),
                },
            });
        }
    }
}

function changeActive() {
    const tabs = block.querySelectorAll('.tabs-wrapper .tab');
    const projects = block.querySelectorAll('.projects-wrapper .project');
    const button = block.querySelector('.js-open-form');

    if (tabs[0]) {
        tabs.forEach((element, id) => {
            element.addEventListener('click', ()=>{
                tabs.forEach(el => {
                    el.classList.remove('active');
                });
                projects.forEach(el => {
                    el.classList.remove('active');
                });
                element.classList.add('active');
                projects[id].classList.add('active');
                button.dataset.name = projects[id].querySelector('.js-name').innerText;
            });
        });
    }
}
